<script setup lang="ts">
import { debounce } from 'lib/helpers/debounce'
import { handleCountrySwitchRouting } from 'lib/storefronts'
import type { Market } from 'types/models/market'
import { clientSetMarketCookie } from 'lib/cookie'
import { HIDDEN_FLAG_COUNTRIES } from '~/configuration/global.configuration'

const emit = defineEmits(['select'])
const storefrontStore = useStorefrontStore()

const searchQuery = ref('')
const { currentMarket, allMarkets } = storeToRefs(storefrontStore)

const filteredMarkets = computed(() => {
  return allMarkets.value
    .filter(
      market =>
        market.name.english.toLowerCase().includes(searchQuery.value)
        || market.name.local.toLowerCase().includes(searchQuery.value)
        || market.countryCode.toLowerCase().includes(searchQuery.value),
    )
    .sort((a, b) => {
      if (a.name.english > b.name.english)
        return 1
      if (a.name.english < b.name.english)
        return -1
      return 0
    })
})

const onSearch = debounce((e: Event) => {
  const target = e.target as HTMLInputElement
  searchQuery.value = target.value.toLowerCase()
}, 300)

async function handleRouting(market: Market) {
  await clientSetMarketCookie(market.countryCode)
  const hasDoneClientRedirect = await handleCountrySwitchRouting(currentMarket.value, market)
  hasDoneClientRedirect && emit('select')
}

const hideTopFlag = computed(() => {
  return HIDDEN_FLAG_COUNTRIES.includes(currentMarket.value.countryCode)
})

function shouldHideFlag(countryCode: string) {
  return HIDDEN_FLAG_COUNTRIES.includes(countryCode)
}
</script>

<template>
  <div class="main-wrapper">
    <div
      v-if="hideTopFlag"
      class="top-flag flag flag-hide"
    />
    <div
      v-else
      class="top-flag flag"
      :style="{
        backgroundImage: `url(/flags/${currentMarket.countryCode.toLowerCase()}.png)`,
      }"
    />

    <header>
      <h4>{{ $t('changeLocale') }}</h4>
      <form data-test="search-country-switcher">
        <img src="/icons/search.svg">

        <input
          type="text"
          required="true"
          autocomplete="off"
          :placeholder="$t('searchCountryModal')"
          @input="onSearch"
        >
      </form>
    </header>
    <div class="country-wrapper">
      <div class="country-grid">
        <button
          v-for="market in filteredMarkets"
          :key="market.countryCode"
          @click="() => handleRouting(market)"
        >
          <div
            v-if="shouldHideFlag(market.countryCode)"
            class="flag flag-hide"
          />
          <div
            v-else
            class="flag"
            :style="{
              backgroundImage: `url(/flags/${market.countryCode.toLowerCase()}.png)`,
            }"
          />
          <span>{{ market.name.english }}</span>
        </button>
      </div>
    </div>
    <div class="overlay-gradient" />
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';

.overlay-gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 8rem;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.489583) 15.62%,
    #ffffff 37.5%,
    #ffffff 100%
  );
}

.country-wrapper {
  overflow: hidden;
  display: flex;
}

.main-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 80%;
  background: white;
  border-top: 2px solid var(--black);

  header {
    width: 100%;
    display: flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    padding: 4rem 2rem 3rem 2rem;

    justify-content: space-between;

    h4 {
      font-size: 2.4rem;
      text-align: center;
      width: 100%;
      padding: 2.5rem 0 2rem 0;
    }
  }

  form {
    width: 100%;
    background-color: #edeff1;
    position: relative;
    display: flex;
    align-items: center;

    img {
      position: absolute;
      margin-left: 1rem;
      height: 2rem;
    }

    input {
      background-color: inherit;
      height: 100%;
      font-size: 1.6rem;
      padding-left: 3.5rem;
      padding-right: 1.5rem;
      height: 4rem;
      width: 100%;
    }
  }

  .country-wrapper {
    overflow: hidden;
    display: flex;

    .country-grid {
      display: grid;
      padding: 0 3rem 8rem 3rem;
      grid-gap: 1.5rem 2.5rem;
      grid-template-columns: repeat(2, 1fr);
      overflow-y: auto;
    }

    span {
      font-size: 1.4rem;
      text-align: left;
    }
    .country-grid > button {
      display: inline-flex;
      align-items: center;
    }
  }

  .flag {
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    border: 0.5px solid #d9d9d9;
    flex-shrink: 0;
  }

  .flag-hide {
    background-color: var(--gray);
    border: none;
  }

  .top-flag {
    position: absolute;
    left: 0;
    top: 3.05rem;
    width: 100%;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 6.1rem;
    width: 6.1rem;
    border: 2px solid var(--black);
  }
}

@media (min-width: $phone) {
  .container {
    align-items: center;
  }
}

@media (min-width: 768px) {
  .overlay-gradient {
    height: 12rem;
  }

  .main-wrapper {
    max-width: 800px;
    width: 100%;
    border: 2px solid var(--black);

    header {
      flex-wrap: nowrap;
      padding: 6rem 6rem 4rem 6rem;

      h4 {
        text-align: left;
        padding: 0;
        padding-right: 2rem;
        font-size: 2.8rem;
      }
    }

    form {
      width: auto;
      input {
        width: auto;
      }
    }

    .country-wrapper {
      span {
        font-size: 1.6rem;
      }
      .country-grid {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 2rem 2.5rem;
        padding: 0 6rem 12rem 6rem;
      }
    }
  }

  .flag {
    width: 4.5rem;
    height: 4.5rem;
  }
  .top-flag {
    display: none;
  }
}

@media (min-width: $laptop) {
  .main-wrapper header h4 {
    font-size: 2.8rem;
  }
}
</style>
